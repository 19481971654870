// Bring in a few things from Bootstrap so we can use the grid, clearfix and vendor mixins (for transition, etc)
@import "../../../../themes/wcm_brand_base/scss/bootstrap/variables";
@import "../../../../themes/wcm_brand_base/scss/bootstrap/mixins/_clearfix";
@import "../../../../themes/wcm_brand_base/scss/bootstrap/mixins/_grid";
@import "../../../../themes/wcm_brand_base/scss/bootstrap/mixins/_vendor-prefixes";

// Bring in the vars and custom mixins defined in the WCMC Bootstrap theme. Sets the WCMC Colors and Breakpoints
@import "../../../../themes/wcm_brand_base/scss/_variables";
@import "../../../../themes/wcm_brand_base/scss/_mixins";

/* =Module-specific styles
----------------------------------------------------------*/

.footer-nav .block-menu-block {
  .menu {
    @include breakpoint($md) {
      display: flex;
      margin: 0;
      padding: 0;
      gap: 20px;

      .menu {
        display: block;
      }
    }
  }
}
